import React from 'react'
import background from '../img/about.jpg'
import {SiBattledotnet} from 'react-icons/si'
import msg from '../img/msg.png'
import {GrCertificate} from 'react-icons/gr'
import {GiSandsOfTime} from 'react-icons/gi'
import CEO from '../img/person.jpg'
import logo from '../img/logo.png'
import { motion } from 'framer-motion'
   
import { FaHandshake } from 'react-icons/fa';
export default function About() {
    const containerStyle = {
        backgroundImage: `url(${background})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '35vh',
        position: 'relative',
        transition: 'background-image 0.6s ease',
        
        
      };
      const overlayStyle = {
        content: '',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Adjust the opacity (last value) for darkness
      };
  return (
    <motion.div
    initial={{ x: -100, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: 100, opacity: 0, transition: { duration: 0.3 } }}
    >
    <div className=''>
        <div className='bg-black opacity-95  relative' 
        style={containerStyle}>
        <div style={overlayStyle}></div>
        <div className="absolute text-5xl text-white mt-24 justify-end uppercase ml-20 tracking-wide">
                About Us
              <div className='flex gap-6'>
              <div className='flex gap-2 mt-8'>
               <SiBattledotnet className='ml-10 text-2xl mt-1'/> <button className='flex text-xl'><a href='/'>Home</a></button>
               </div>
               <div className='flex mt-7'>
                 <button className='text-lg  opacity-80 text-green-400 p-1 rounded-full'><a href='/contactUs'>About US</a></button>
               </div>
              </div>
             
        </div>
        </div>
       
       <div className='aboutUSS'><br/>
        <div className=''><span className='text-3xl font-extralight tracking-wider uppercase ml-9'>
            Why we are here
        </span>
        <p className='ml-5 mt-5 text-xl font-extralight'>
        Green Lanka Energy Engineering (Private) Limited is a consultancy and engineering company established by professional engineers who have vast local and international experience in energy and engineering. The company’s main aim is to provide renewable energy and engineering solutions for meeting the energy needs of our customers at a lower cost. We are pleased to actively contribute to the energy transformation presently taking place in Sri Lanka, leading to an environmentally sustainable national energy echo system. The knowledge and experience of our consultant engineers could assist you in planning, designing, procurement and implementation of projects such as making the maximum use of solar energy to power your households and power supply and process improvements for commercial business and industries. We are always prepared to assist individuals and organizations who need electricity and energy engineering services.
               </p>
        
               
        </div>
      
        <div className='text-center text-4xl mt-11'>Why Choose US</div>
         <div className='flex md:flex-row flex-col items-center ml-5 mt-10 cursor-cell'>

    <div className='text-center p-5 whyunder1'>
        <div class='flex justify-center'>  
            <span><FaHandshake className='text-red-700 text-6xl'/></span>
        </div>
        <span className='text-3xl font-extralight uppercase'>Trusted Service</span><br/>
        <span className='text-lg font-extralight'>Your trusted partner in renewable energy installation services for one decade.</span>
        <div className='underliners'></div>
    </div>

    <div className='text-center p-5 whyunder2'>
        <div class='flex justify-center'>  
            <span><GrCertificate className='text-red-700 text-6xl'/></span>
        </div>
        <span className='text-3xl font-extralight uppercase'>Registered company</span><br/>
        <span className='text-lg font-extralight'><p>Our registered company, duly incorporated</p><p>
        follows all legal regulations, ensuring trust and reliability in every endeavor.</p></span>
        <div className='underliners'></div>
    </div>

    <div className='text-center p-5 whyunder3'>
        <div class='flex justify-center'>  
            <span><GiSandsOfTime className='text-red-700 text-6xl'/></span>
        </div>
        <span className='text-3xl font-extralight uppercase'>Years of experience</span><br/>
        <span className='text-lg font-extralight'>Decades of expertise, delivering excellence through years of experience.</span>
        <div className='underliners'></div>
    </div>
        </div>


         </div>


            <div className='p-10 flex flex-col md:flex-row gap-6'>

            <div className=''>
             <img src={msg}  alt='msg'/>
            
           </div>

           <div className=''>
            <p className='flex text-2xl font-extrabold gap-4 ml-5'> <SiBattledotnet className=' text-4xl'/>MASTER BEHIND THE VISION...</p>
              <div className='container mt-5'>
               <p className=''>Green Lanka Energy Engineering was established and is currently under the guidance of Mr. S. Bogahawatte, who serves as both the Chairman and Managing Director of the company. Mr. Bogahawatte holds the esteemed title of a seasoned electrical engineer, with over 40 years of extensive experience in the field of electrical engineering. Prior to his role in Green Lanka Energy Engineering, he held the esteemed position of Deputy Electrical Manager at C.E.B. and served as a Senior Lecturer at the "General Sir John Kotelawala Defence University."
                His expertise has earned him the status of an accredited consultant engineer for electrical engineering, offering his invaluable services to various organizations. Notably, Mr. Bogahawatte is actively involved in the design of electrical distribution systems for factories and commercial establishments.
               </p>
             </div>
            </div>

            <div className='ml-5'>
              <div className='flex items-center justify-center'>
              <img src={CEO} alt='msg' className='w-96 h-auto'/>
              </div>
             
              <p className='text-center text-xl font-sans tracking-tighter'>Mr. S. Bogahawatte</p>
              <p className='text-center  tracking-tighter font-extralight text-xl'>Chairman</p>
              <div className='underliners'></div>
            </div>

            </div>
        
         {/* Related Company */}

<div className='mb-0'>
<h1 className='text-black text-5xl font-semibold text-center animate-fade-in-letters py-4 mb-2'style={{ borderBottom: '1px solid gray' }}>OUR SISTER COMPANIES</h1>
<main className='container mx-auto my-5'>
  <div className='flex justify-center'>
    <div className='bg-gray-200 rounded-lg shadow-md p-6'>
      <div className='flex flex-col items-center'>
        <img src={logo} className='h-40 w-40' alt='Green Lanka Energy IT Solutions' title='Green Lanka Energy IT Solutions' />
        <h3 className='text-center text-xl font-semibold'>Green Lanka Energy IT Solutions</h3>
        <p className='mt-2 text-center text-gray-500'>Digitalize Your Innovative Ideas</p>
        <button className='mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full'>
          <a href='https://gleits.com/' target='_blank' rel='noreferrer'>
            Visit
          </a>
        </button>
      </div>
    </div>
  </div>
</main>

  <br/> 
</div>
        
     
    </div>
    </motion.div>
  )
}
