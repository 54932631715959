import React, { useEffect, useState ,useRef } from 'react';
import Imgss from '../img/projects/Picture10.jpg';
import Imgss1 from '../img/projects/Picture26.jpg';
import Imgss2 from '../img/projects/inventor.jpg';
import Imgss3 from '../img/projects/e.jpeg';
import Imgss4 from '../img/projects/workse.png';
import banner1 from '../img/back.jpg'
import banner2 from '../img/solarBg.jpeg'
import banner3 from '../img/etesting.jpeg'
import { motion } from 'framer-motion';

 
 

export default function Projects() {
  const [background, setBackground] = useState('');
  const [content, setContent] = useState({});

  const images = [banner1, banner2,banner3];

  const contentVariations = [
    {
      title: 'Empowering Solar Solutions',
      text: 'Transforming ideas into solar-powered reality through top-notch installation and electrical consulting services.',
      buttonText: 'Explore Our Solar Work',
    },
    {
      title: 'Innovative Solar Excellence',
      text: 'Pioneering innovation and excellence in every solar project we undertake, backed by expert electrical consulting.',
      buttonText: 'Discover Our Expertise',
    },
    {
      title: 'Crafting Quality Solar Installations',
      text: 'Committed to crafting enduring quality in solar installations. Explore our craftsmanship in sustainable energy solutions.',
      buttonText: 'Discover Solar Quality Work',
    },
   
 
    // Add more content variations as needed
  ];

  const setRandomBackground = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImageUrl = images[randomIndex];
    setBackground(randomImageUrl);

    // Randomly select content variation
    const randomContentIndex = Math.floor(Math.random() * contentVariations.length);
    setContent(contentVariations[randomContentIndex]);
  };

  useEffect(() => {
    setRandomBackground();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRandomBackground();
    }, 5000);

    return () => clearInterval(interval);

   


  }, []);
  const elementsRef = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slide-in');
          observer.unobserve(entry.target); // Stop observing once animated
        }
      });
    }, options);

    elementsRef.current.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect(); // Clean up the observer when the component unmounts
    };
  }, []);

  const containerStyle = {
    backgroundImage: `url(${background})`,
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    position: 'relative',
    transition: 'background-image 0.6s ease',
  };
  const overlayStyle = {
    content: '',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Adjust the opacity (last value) for darkness
  };

  return (
    <motion.div 
    initial={{ x: -100, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: 100, opacity: 0, transition: { duration: 0.3 } }}
    >
    <div>
      <div
        className="relative bg-black  w-auto h-screen"
        style={containerStyle}
      > <div style={overlayStyle}></div>
        {/* Content Box */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-black bg-opacity-75 p-6 rounded-lg shadow-lg text-center text-white">
            <h1 className="text-5xl mb-4 font-extralight">{content.title}</h1>
            <p className="text-lg">{content.text}</p>
            <button className="mt-4 hover:text-blue-500  font-semibold py-2 px-4 rounded-full border border-emerald-600">
              {content.buttonText}
            </button>
          </div>
        </div>
      </div>

      <div className='w-full h-auto relative '>
       
        

        {/* what we do */}
        <div>
          <div className='text-center text-4xl font-bold mt-10'>Completed Projects</div>
          <div className='text-center mt-3 h-auto p-4 text-xl font-extralight'>
            <p>
            Explore our portfolio of completed solar installation projects, showcasing our expertise in harnessing sustainable energy through captivating visuals of our successful ventures.            </p>
          </div>
 
          <div className='relative gap-5 p-5 flex flex-col md:flex-row items-center'>
      <div ref={(el) => elementsRef.current.push(el)} className='bg-black w-80 h-80 bx1'>
        <img src={Imgss} alt='img' className='w-full h-full object-cover hel'/>
        <div className='image-overlay'>
          <button className='text-white  py-2 px-4 rounded-full  border-white  border hover:border-red-600  btn1'>Read More</button>
        </div>
      </div>
      <div ref={(el) => elementsRef.current.push(el)} className='bg-black w-80 h-80 md:mt-56 bx2'>
      <img src={Imgss1} alt='img' className='w-full h-full object-cover'/>
      <div className='image-overlay'>
      <button className='text-white  py-2 px-4 rounded-full  border-white  border hover:border-red-600  btn1'>Read More</button>
      </div>
      </div>
      <div ref={(el) => elementsRef.current.push(el)} className='bg-black w-80 h-80 bx3'>
      <img src={Imgss2} alt='img' className='w-full h-full object-cover'/>
      <div className='image-overlay'>
      <button className='text-white  py-2 px-4 rounded-full  border-white  border hover:border-red-600  btn1'>Read More</button>
      </div>
      </div>
      <div ref={(el) => elementsRef.current.push(el)} className='bg-black w-80 h-80 md:mt-56 bx4'>
      <img src={Imgss3} alt='img' className='w-full h-full object-cover'/>
      <div className='image-overlay'>
      <button className='text-white  py-2 px-4 rounded-full  border-white  border hover:border-red-600  btn1'>Read More</button>
      </div>
      </div>
      <div ref={(el) => elementsRef.current.push(el)} className='bg-black w-80 h-80 bx5'>
      <img src={Imgss4} alt='img' className='w-full h-full object-cover'/>
      <div className='image-overlay'>
      <button className='text-white  py-2 px-4 rounded-full  border-white  border hover:border-red-600  btn1'>Read More</button>
      </div>
      </div>
    </div>
          
        </div>

      </div>
     

      <div className='relative mt-10 py-10'>
       
          <div className='flex flex-col md:flex-row gap-10'>
          <div className='h-56  rounded-e-full w-8/12 bg-purple-800'>
            <p className='text-center text-lg md:text-3xl py-16 font-extralight uppercase'> IN SEARCH OF A BUDGET-FRIENDLY SOLAR INSTALLATION & ELECTRICAL CONSULTING PARTNER FOR  <span className='font-bold'>YOUR NEXT PROJECT?</span></p>
          </div>

          <div className='flex  justify-end items-start py-20 '> 
            <button className=' text-red-500 font-bold py-2 px-4 rounded-full  border-red-500 text-4xl border hover:bg-red-500 hover:text-white hover:border-white getqty'><a href='/contactUs'>GET A QUOTE</a></button>

           </div>  
           </div>

      </div>

      

    </div>
    </motion.div>
  );
}