import React from 'react'
import Navbar from './main/Navbar'
import Footer from './main/Footer'
import { BrowserRouter as Router} from 'react-router-dom'
import AnimatedRoute from './Component/AnimatedRoute'
export default function App() {

  return (
    <div>
        <Router>
        <Navbar/>
        <AnimatedRoute/>
        <Footer/>
        </Router>
  
     
    </div>
  )
}
