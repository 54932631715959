


export const sliderData = [
    {

      heading: "Best solar provider service",
      // desc: " We use innovative approaches to solve the toughest challenges for company businesses.",
    },
    {

      heading: "Engineers and consultants for solar and electrical construction works",
      // desc: "  We can’t solve problems by using the same kind of thinking we used when we created them.",
    },
    {

      heading: "Electrical installation works",
      // desc: "  We collaborate with clients, colleagues, and our stakeholders to share and support.",
    },
    {

      heading: "Best solar provider service",
      // desc: "  We collaborate with clients, colleagues, and our stakeholders to share and support.",
    },
    {

      heading: "Engineers and consultants for solar and electrical construction works",
      // desc: "  We can’t solve problems by using the same kind of thinking we used when we created them.",
    },
    {

      heading: "Electrical installation works",
      // desc: " We use innovative approaches to solve the toughest challenges for company businesses.",
    },
  ];