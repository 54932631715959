import React from "react";
import './Service.css';
import Typed from 'react-typed';
import {motion} from 'framer-motion'
import {SiBattledotnet} from 'react-icons/si'
import {  Dialog, DialogContent, DialogTitle,  IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"
import { useState } from "react";
import pwr01 from '../img/powerplnt01.jpg'
import pwr02 from  '../img/pwrplant2.jpeg'
import meter from '../img/test.jpg'
import solar01 from '../img/Picture27.jpg'
import solar02 from '../img/Picture25.jpg'
import energypic01 from '../img/energy 01.webp'
import { Scrollbars } from 'react-custom-scrollbars';
import background from '../img/Services.jpg'

function Service ()  {
  const [open,openchange]=useState(false);
  const [open1,openchange1]=useState(false);
    const [open2,openchange2]=useState(false);
  const functionopenpopup=()=>{
      openchange(true);
  }
  const functionopenpopup1=()=>{
    openchange1(true);
}
    const functionopenpopup2=()=>{
        openchange2(true);
    }
  const closepopup=()=>{
      openchange(false);
  }

  const closepopup1=()=>{
    openchange1(false);
}
    const closepopup2=()=>{
        openchange2(false);
    }

    const containerStyle = {
      backgroundImage: `url(${background})`,
      backgroundAttachment: 'fixed',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      minHeight: '35vh',
      position: 'relative',
      transition: 'background-image 0.6s ease',
      
      
    };
    const overlayStyle = {
      content: '',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)', // Adjust the opacity (last value) for darkness
    };


    return (
        <motion.div
    initial={{ x: -100, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: 100, opacity: 0, transition: { duration: 0.3 } }}
    >
            <div className='bg-black opacity-95  relative' 
        style={containerStyle}>
        <div style={overlayStyle}></div>
        <div className="absolute text-5xl text-white mt-24 justify-end uppercase ml-20 tracking-wide">
                Services
              <div className='flex gap-6'>
              <div className='flex gap-2 mt-8'>
               <SiBattledotnet className='ml-10 text-2xl mt-1'/> <button className='flex text-xl'><a href='/'>Home</a></button>
               </div>
               <div className='flex mt-7'>
                 <button className='text-lg  opacity-80 text-green-400 p-1 rounded-full'><a href='/Service'>Services</a></button>
               </div>
              </div>
             
        </div>
        </div>
    
             <div className='w-4/5 bg-white bg-opacity-50 py-5 px-8 sbo1'>
               <h1 className="text-black text-3xl font-bold  animate-fade-in-letters mb-5">  We focus on</h1> 
             <li className='text-black  animate-fade-in-letters'>
        
        <Typed
          strings={[
            "Supply and installation of renewable energy (e.g. solar and bio-mass) systems for electricity generation, heating and cooling."
          ]}
          typeSpeed={30}
          backSpeed={false}
          showCursor={false}
          
         />
        </li>
    
        <li className='text-black  animate-fade-in-letters'>
        
        <Typed
          strings={[
            "Energy audits and energy efficiency improvement."
          ]}
          typeSpeed={30}
          backSpeed={false}
          showCursor={false}
          
         />
        </li>
        <li className='text-black  animate-fade-in-letters'>
        
        <Typed
          strings={[
            "Design and installation of electrical wiring, heating and cooling systems."
          ]}
          typeSpeed={30}
          backSpeed={false}
          showCursor={false}
          
         />
        </li>
        <li className='text-black  animate-fade-in-letters'>
        
        <Typed
          strings={[
            "Design and construction of connections to high voltage grid including coordination of electricity supply with CEB."
          ]}
          typeSpeed={30}
          backSpeed={false}
          showCursor={false}
          
         />
        </li>
      </div>

    <br/>
      
    
                <div className="container">
                    <div className="flex flex-col md:flex-row  md:gap-3">
    
    
                        <div className="col__3">
                             <div className="service__box pointer">
                                <div className="icon">
                                <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2 16.1A5 5 0 0 1 5.9 20M2 12.05A9 9 0 0 1 9.95 20M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6"></path>
                      <line x1="2" y1="20" x2="2" y2="20"></line>
                    </svg>
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Solar PV & Solar Thermal</h1>
                                    <p className="p service__text p__color">
                                       Reduce energy costs and Improve your comforts.
                                    </p>
                                    <p className="p service__text p__color">
                                    Supply and Installation for Industries  Commercial buildings Residencies.
                                    </p><br/>
                                    <button    onClick={functionopenpopup2} rel="noopener noreferrer" className="btn-primary round-btn hover:text-teal-400">
  Learn More
</button>

                                   </div>
                             </div>
                        </div>
    
                        <div className="col__3">
                             <div className="service__box pointer">
                                <div className="icon">
                                <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>

                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Energy Efficiency Improvement Advice</h1>
                                    <p className="p service__text p__color">
                                        Improve electricity safety & better utilization of electricity Process  management & Efficiency improvement.
                                    </p>
                                    <br/>
                                    <button  onClick={functionopenpopup} rel="noopener noreferrer"className="btn-primary round-btn hover:text-teal-400">
  Learn More
</button>
                                </div>
                             </div>
                        </div>
    
                        <div className="col__3">
                             <div className="service__box pointer">
                                <div className="icon">
                           
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                      <polyline points="2 17 12 22 22 17"></polyline>
                      <polyline points="2 12 12 17 22 12"></polyline>
                    </svg>
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Electrical Installation</h1>
                                    <p className="p service__text p__color">
                                      Designing Lighting & Power Systems Installation & Testing For the least cost with Quality & for your necessity.
                                    </p>
                                    
                                    <br/>
                                    <button  onClick={functionopenpopup1} rel="noopener noreferrer" className="btn-primary round-btn hover:text-teal-400">
  Learn More
</button>
                                </div>
                             </div>
                        </div>
                      
                    </div>
                </div><br/>
                
            <div className='w-4/5 bg-white bg-opacity-50 py-8 px-8   sbo'>
               <h1 className="text-black text-3xl font-bold  animate-fade-in-letters mb-5">  We provide value to our customers through</h1> 
             <li className='text-black  animate-fade-in-letters'>
        
        <Typed
          strings={[
            "Clear identification of the customer needs and project constraints."
          ]}
          typeSpeed={30}
          backSpeed={false}
          showCursor={false}
          
         />
        </li>
    
        <li className='text-black  animate-fade-in-letters'>
        
        <Typed
          strings={[
            "Effective engagement with the customers during every stage throughout the project."
          ]}
          typeSpeed={30}
          backSpeed={false}
          showCursor={false}
          
         />
        </li>
        <li className='text-black   animate-fade-in-letters'>
        
        <Typed
          strings={[
            "After sale monitoring and support, to realize the maximum financial benefits."
          ]}
          typeSpeed={30}
          backSpeed={false}
          showCursor={false}
          
         />
        </li>
        <li className='text-black   animate-fade-in-letters'>
        
        <Typed
          strings={[
            "Identifying opportunities to reduce cost and add value to the customer operations."
          ]}
          typeSpeed={20}
          backSpeed={false}
          showCursor={false}
          
         />
        </li>
      </div>
  


      {/* Dialog box */}
   <Dialog open={open} onClose={closepopup} fullWidth maxWidth="md" className="dialog__background" PaperProps={{ style: { borderRadius: '20px' } }}>
     <div style={{ backgroundColor: '#FEFFFD'}}>
      <DialogTitle style={{ backgroundColor: '#2ecc71', color: '#ffffff', textAlign: 'center',fontSize: '26px', fontWeight: 'bold' }}>
        Energy Efficiency Improvement Advices
        <IconButton onClick={closepopup} style={{ position: 'absolute', top: 10, right: 10 }}>
          <CloseIcon style={{ color: '#ffffff' }} />
        </IconButton>
      </DialogTitle>
         <Scrollbars
             style={{ height: '550px', borderRadius: '10px' }}
             renderThumbVertical={({ style }) => <div style={{ ...style, backgroundColor: '#888', borderRadius: '10px' }} />}
             renderThumbHorizontal={({ style }) => <div style={{ ...style, backgroundColor: '#888', borderRadius: '10px' }} />}
         >
      <DialogContent> <br></br>

        <Stack spacing={3} margin={2}>
          <div className="solar__container">
            {/* First row */}
            <div className="flex flex-col md:flex-row md:gap-3">
              <div className="container bg-gradient-to-r from-green-400 to-green-300 p-4 rounded-lg shadow-md">
              <p className="text-gray-900 mt-3" style={{ fontSize: '20px'}}>
                  We study the energy usage of plants by preliminary energy audit. If further
                  investigation is required, a detailed energy audit will be carried out, and
                  provide energy audit reports with recommendations. Also we are monitoring after 
                  implementation of Installation of capacitor banks and Various demand-side management activities.
                </p>
              </div>

              <div className="container">
                <img src={meter} alt="solar" className="w-full h-full" />
              </div>
            </div>
          </div>
          <img src={energypic01} alt="solar" className="w-full h-full" /> <br></br>
        </Stack>
      </DialogContent>
         </Scrollbars>
      </div>
    </Dialog>



            {/* Dialog box */}
            <Dialog open={open1} onClose={closepopup1} fullWidth maxWidth="md" className="dialog__background" PaperProps={{ style: { borderRadius: '20px'} }}>
                <dev style={{ backgroundColor: '#FEFFFD'}}>
                    <DialogTitle style={{ backgroundColor: '#2ecc71', color: '#ffffff', textAlign: 'center',fontSize: '26px', fontWeight: 'bold' }}>
                        Electrical Installation
                        <IconButton onClick={closepopup1} style={{ position: 'absolute', top: 10, right: 10 }}>
                            <CloseIcon style={{ color: '#ffffff' }}>
                            </CloseIcon>
                        </IconButton>
                    </DialogTitle>

                    <Scrollbars
                        style={{ height: '550px', borderRadius: '10px' }}
                        renderThumbVertical={({ style }) => <div style={{ ...style, backgroundColor: '#888', borderRadius: '10px' }} />}
                        renderThumbHorizontal={({ style }) => <div style={{ ...style, backgroundColor: '#888', borderRadius: '10px' }} />}
                    >

                        <DialogContent>
                            {/* <DialogContentText>Do you want remove this user?</DialogContentText> */}
                            <Stack spacing={3} margin={2}>
                                <div className="solar__container">
                                    {/* First row */}
                                    <div className="flex flex-col md:flex-row md:gap-3">
                                        <div className="container bg-gradient-to-r from-green-400 to-green-300 p-4 rounded-lg shadow-md">
                                            <p className="text-gray-900 mt-3" style={{ fontSize: '20px'}}>We provide a comprehensive range of electrical services to meet diverse needs.
                                                Our offerings include providing test reports to C.E.B and L.E.C.O,
                                                conducting load studies for new development projects,
                                                designing electrical installations in line with IEE regulations,
                                                manufacturing electrical distribution panels,
                                                and implementing electrical systems for various establishments,
                                                such as factories, garment factories, hotels, and commercial buildings.
                                                Additionally, we conduct rigorous testing and provide detailed reports to ensure the integrity and efficiency of electrical installations.
                                                Our services also encompass the maintenance of electrical distribution systems,
                                                prioritizing safety, efficiency, and customer satisfaction.
                                                With a commitment to excellence, we are your trusted partner for all electrical requirements.
                                            </p>
                                        </div> &nbsp;

                                        <div className="container"> <br/>
                                            <img src={pwr01} alt="solar" className="w-fit h-fit mb-6" /> <br/>
                                            <img src={pwr02} alt="solar" className="w-fit h-fit mb-4" />
                                        </div>

                                    </div>
                                </div>
                                <br></br>

                            </Stack>
                        </DialogContent>
                    </Scrollbars>
                </dev>
            </Dialog>


            {/* Dialog box for solar */}
            <Dialog open={open2} onClose={closepopup2} fullWidth maxWidth="md" className="dialog__background"  PaperProps={{ style: { borderRadius: '20px' } }}>
                <dev style={{ backgroundColor: '#FEFFFD' }}>
                    <DialogTitle style={{ backgroundColor: '#2ecc71', color: '#ffffff', textAlign: 'center',fontSize: '26px', fontWeight: 'bold' }}>
                    Solar PV & Solar Thermal
                        <IconButton onClick={closepopup2} style={{ position: 'absolute', top: 10, right: 10 }}>
                            <CloseIcon style={{ color: '#ffffff' }} />
                        </IconButton>
                    </DialogTitle>
                    <Scrollbars
                        style={{ height: '550px', borderRadius: '10px' }}
                        renderThumbVertical={({ style }) => <div style={{ ...style, backgroundColor: '#888', borderRadius: '10px' }} />}
                        renderThumbHorizontal={({ style }) => <div style={{ ...style, backgroundColor: '#888', borderRadius: '10px' }} />}
                    >
                    <DialogContent> 
                        <Stack spacing={3} margin={2}>
                            <div className="solar__container">
                                {/* First row */}
                                <div >
                                    <div className=" bg-gradient-to-r from-green-400 to-green-300 p-4 rounded-lg shadow-md" style={{ maxWidth: '800px'}}>
                                      
                                        <p className="text-gray-900 mt-3" style={{ fontSize: '20px'}}>

                                        <img src={solar01} alt="solar" className="w-fit h-fit mb-3" /> 

                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; At our company, we specialize in investigating and designing the most efficient solar energy systems. 
                                        Our expertise allows us to offer comprehensive project reports, 
                                        providing detailed insights into the feasibility and benefits of each system. 
                                        Whether it's rooftop or ground-mounted solar setups, 
                                        we have the capability and experience to handle both. 
                                        Moreover, we are dedicated to offering pre-feasibility designs for solar systems, 
                                        ensuring that our clients have a clear understanding of the potential outcomes before proceeding with any project.
                                        </p> <br/>
                                        <img src={solar02} alt="solar" className="w-fit h-fit mb-3" /> 
                                        <p className="text-gray-900 mt-3" style={{ fontSize: '20px'}}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In addition to designing solar systems, 
                                        we also construct medium voltage lines tailored to regenerate systems. 
                                        This ensures a seamless integration of renewable energy into the existing power infrastructure, 
                                        maximizing the benefits of solar energy while contributing to a sustainable and greener future. 
                                        Our commitment to innovation and expertise in the solar industry makes us the ideal partner for all your solar energy needs.
                                          </p>
                                    </div>

                                    {/* <div className="container"> <br/>
                                            <img src={solar01} alt="solar" className="w-fit h-fit mb-3" /> 
                                            <img src={solar03} alt="solar" className="w-fit h-fit mb-4" />
                                        </div> */}
                                </div>
                            </div>
                            
                        </Stack>
                    </DialogContent>
                    </Scrollbars>
                </dev>
            </Dialog>

        </motion.div>
        
    )
}

export default Service;


