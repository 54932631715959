import React, { useState, useEffect } from 'react';
import sample from '../img/consult.png'
import growth from '../img/growth.png'
import sola from '../img/sola.png'
import { BsBookmarks } from 'react-icons/bs';
import { ImHammer2 } from 'react-icons/im';
import Typed from 'react-typed';
import ImageCard from './ImageCard';
import { CiBank } from "react-icons/ci";
import {VscLaw} from "react-icons/vsc"
import './Home.css';
import {motion} from 'framer-motion'
import Partnership from './PartnerShip/Partnership';
import Slide from './Silder/Slider'
 
import{VscWorkspaceTrusted} from 'react-icons/vsc'
import{AiOutlineFieldTime} from 'react-icons/ai'
import {MdOutlineEngineering} from 'react-icons/md'
export default function Home() {
  const [staffMembers, setStaffMembers] = useState(0);
  const[caseSolved,setCaseSolved]=useState(0);
  const[trustedPartners,setTrustedPartners]=useState(0);  
  const[topLaw,settopLaw]=useState(0);

 

  useEffect(() => {
    const interval = setInterval(incrementCounts, 100); // Increment counts every 100 milliseconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

 

  const incrementCounts = () => {
    setStaffMembers((prevCount) => {
      if (prevCount === 25) return 25;
      return prevCount + 1;
    
  });
  setCaseSolved((prevCount) => {
    if (prevCount === 65) return 65;
    return prevCount + 1;
  });

  setTrustedPartners((prevCount) => {
    if (prevCount === 45) return 45;
    return prevCount + 1;
  });

  settopLaw((prevCount) => {
    if (prevCount === 10) return 10;
    return prevCount + 1;
  });

}

  return (
    <motion.div
     className='home'
     initial={{ x: -100, opacity: 0 }}
     animate={{ x: 0, opacity: 1 }}
     exit={{ x: 100, opacity: 0, transition: { duration: 0.3 } }}
     
    >
        <div className='slide-container'>
         <Slide/>
         <div className='Bar'>
        <div className='animate-fade-in-letters py-4 flex flex-col md:flex-row md:justify-center '>
            <div className="bg-[#0D1D34] shadow-lg  p-4">
             <div className="flex flex-col md:flex-row  md:gap-3"> 
              <div className="mt-4"><VscWorkspaceTrusted className='text-4xl text-blue-500'/></div> 
              <div className="mt-2">
                <h3 className="text-xl font-bold text-white">Trusted Services</h3>
                <p className="text-gray-200 b_tx">Exceptional Services Built On Trust</p>
                 
              </div>
              </div>
            </div>

            <div className="bg-gray-800  shadow-lg  p-4">
            <div className="flex flex-col md:flex-row  md:gap-3"> 
            <div className="mt-4"><AiOutlineFieldTime className='text-4xl text-green-500'/></div> 
              <div className="mt-2">
                <h3 className="text-xl font-bold text-white">24/7 Support</h3>
                <p className="text-gray-200 b_tx">SL(+94) 112 518 058</p>
               </div>
               </div>
            </div>

            <div className="bg-[#0D1D34]  shadow-lg p-4">
            <div className=" flex flex-col md:flex-row  md:gap-3"> 
            <div className="mt-4"><MdOutlineEngineering className='text-4xl text-red-500'/></div> 
              <div className="mt-2">
                <h3 className="text-xl font-bold text-white">Pro-Level Engineering</h3>
                <p className="text-gray-200 b_tx">Technical Experts With Industry Experience</p>
              </div>
              </div>
            </div>
            </div>
    </div>
         </div>
        
      <div className='tag-container'>   
      
      <h1 className='text-black text-5xl font-bold text-center animate-fade-in-letters py-4'style={{ borderBottom: '1px solid gray' }}>WE OFFER</h1>
      <br/>
             <main className='flex flex-col md:flex-row md:justify-center md:gap-8' >
              

              <ImageCard imgSrc={sample} >
                           
              </ImageCard>

              <ImageCard imgSrc={growth} >
               </ImageCard>
              <ImageCard imgSrc={sola} >
              </ImageCard>
             </main>
             
        <div className=' py-4 px-8'>
          
          <div className='row gap-12 mt-6'>
            <div className='flex flex-col items-center justify-center'>
              <BsBookmarks className='text-6xl text-blue-400' />
              <p className='text-white-500 text-xl mt-2 text-center font-bold'><p className='text-4xl'>{staffMembers}</p>STAFF MEMBERS<br/></p>
            </div>
            <div className='flex flex-col items-center justify-center'>
              <ImHammer2 className='text-6xl text-green-500' />
              <p className='text-black-500 text-xl mt-2 text-center font-bold'><p className='text-4xl'>{caseSolved}</p>CASES SOLVED<br /></p>
            </div>
            <div className='flex flex-col items-center justify-center'>
              <CiBank className='text-6xl text-red-300' />
              <p className='text-black-500 text-xl mt-2 text-center font-bold'><p className='text-4xl'>{topLaw}</p>TRUSTED PARTNERS<br /></p>
            </div>
            <div className='flex flex-col items-center justify-center'>
              <VscLaw className='text-6xl text-yellow-400' />
              <p className='text-black-500 text-xl mt-2 text-center font-bold'><p className='text-4xl'>{trustedPartners}</p>TOP 10 LAW FIRMS<br /></p>
            </div>
          </div>
      </div><br/><br/>
      <div class="container flex flex-col items-center justify-center   rounded-lg">
  <p class="mt-4 text-2xl text-center">The Establishment of a Renewable Energy Plant</p>
  <a href="https://www.youtube.com/watch?v=_OmeziQA6js" target="_blank" rel="noopener noreferrer" class="relative mt-4">
    <img src="https://img.youtube.com/vi/_OmeziQA6js/maxresdefault.jpg" alt="Video Thumbnail" class="h-56 md:h-96 object-cover rounded-lg shadow-lg"/>
    <div class="absolute inset-0 flex items-center justify-center">
  <a href="https://www.youtube.com/watch?v=_OmeziQA6js" target="_blank" rel="noopener noreferrer" class="relative">
    <img src="https://img.icons8.com/color/48/000000/youtube-play.png" alt="YouTube Play Button" class="w-16 h-16"/>
  </a>
</div>


  </a>
</div>
      {/* partnership badge viewer */}<br/>
      <div className='relative'>
  
  <div className='z-10 relative absolute bottom-0 left-0'>
    <Partnership />
  </div>
</div>

        <br/><br/>
      <div className='bbo'>
       
      <div className='bg-black bg-opacity-80 py-4 px-8'>
         <h1 className='text-white text-3xl  text-center animate-fade-in-letters font-extralight'>
    
    <Typed
      strings={[
        "The professional Engineers of our company are registered consultants for sustainable energy authority of Sri Lanka and Ceylon Electricity Board."
      ]}
      typeSpeed={20}
      backSpeed={false}
      showCursor={false}
      
     />
    </h1><br/><br/><br/>
    <h1 className='text-green-400 text-3xl  text-center animate-fade-in-letters font-extralight'>
    
    <Typed
      strings={[
        "Green Lanka Energy Engineering Pvt Ltd"
      ]}
      typeSpeed={10}
      backSpeed={false}
      showCursor={false}
      
     />
    </h1>
  </div>
</div>

<br/>
      </div>
     


    </motion.div>
  );
}

