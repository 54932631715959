import { useState, useEffect } from "react";
import { sliderData } from "./sliderData";
import "./Slider.css";
import img11 from '../../img/background1.jpg';

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 5000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
    console.log("next");
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
    console.log("prev");
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  

  return (
    <>
    <div>       
    <div className="slider">

      {sliderData.map((slide, index) => {
        return (
          <div
            className={index === currentSlide ? "slide current" : "slide"}
            key={index}
          >
            {index === currentSlide && (
              <div>
                  <div className='relative top-0 left-1/2 transform -translate-x-1/2 text-center w-full'>
          <div className='bg-black bg-opacity-70 py-4 px-8'>
            <div className='text-3xl text-white font-bold'>
              WELCOME TO <span className='text-green-400 mr-2'>GREEN</span>LANKA ENERGY ENGINEERING
            </div>
          </div>
        </div>
               
                <img src={ img11} alt="slide" className='image' /> 
                
                <div className="content">
                  <h2>{slide.heading}</h2>
                  <hr />

                    
                </div> 
                
              </div>
              
            )}
          </div>
            
        );
      })}
     
    </div>
  
    </div>  </>
  );
};

export default Slider;