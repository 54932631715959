import React, { useEffect, useRef } from 'react';
import { data } from './mockData';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
 
function Partnership() {
  const sliderRef = useRef(null);

  useEffect(() => {
    // Function to automatically scroll the slider every 3 seconds
    const autoScroll = () => {
      if (sliderRef.current) {
        sliderRef.current.scrollLeft += 500;
      }
    };

    // Start automatic scrolling
    const interval = setInterval(autoScroll, 3000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 500;
    }
  };

  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 500;
    }
  };

  return (
    <>
        <h1 className='text-black text-5xl font-bold text-center animate-fade-in-letters py-4'style={{ borderBottom: '1px solid gray' }}>PRODUCTS</h1>
        <br/>
      <div className='relative flex items-center'>
        <MdChevronLeft className='opacity-50 cursor-pointer hover:opacity-100' onClick={slideLeft} size={40} />
        <div
          ref={sliderRef}
          id='slider'
          className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'
        >
          {data.map((item, index) => (
            <img
              key={index}
              className='w-[200px] h-[120px] opacity-80 inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300'
              src={item.img}
              alt='/'
            />
          ))}
        </div>
        <MdChevronRight className='opacity-50 cursor-pointer hover:opacity-100' onClick={slideRight} size={40} />
      </div>
    </>
  );
}

export default Partnership;
