import React from 'react'
import Home from './Home'
import Service from './Service'
import ContactUs from './ContactUs'
import AboutUs from './AboutUs'
import Project from './Projects'
import {AnimatePresence} from 'framer-motion'
import {Route, Routes,useLocation} from 'react-router-dom'
export default function AnimatedRoute() {
 
    const location = useLocation();
    return (
  <AnimatePresence>
    <Routes location={location} key={location.pathname}>
    <Route exact path="/"  element={<Home/>}></Route>
    <Route exact path="/Service"  element={<Service/>}></Route>
    <Route exact path="/ContactUs"  element={<ContactUs/>}></Route>
    <Route exact path="/AboutUs"  element={<AboutUs/>}></Route>
    <Route exact path="/Projects"  element={<Project/>}></Route>
    
    </Routes>
  </AnimatePresence>
    
  )
}
