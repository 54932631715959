import img1 from '../../img/Partner/1200px-Logo_SMA.svg.png';
import img2 from '../../img/Partner/Fimer_Logo_RGB_Blue_neg-removebg-preview.png';
import img3 from '../../img/Partner/output-onlinejpgtools-removebg-preview.png';
import img4 from '../../img/Partner/output-onlinejpgtools__10_-removebg-preview.png';
import img5 from '../../img/Partner/output-onlinejpgtools__2_-removebg-preview.png';
import img6 from '../../img/Partner/output-onlinejpgtools__3_-removebg-preview.png';
import img7 from '../../img/Partner/output-onlinejpgtools__5_-removebg-preview.png';
import img8 from '../../img/Partner/output-onlinejpgtools__6_-removebg-preview.png';
import img9 from '../../img/Partner/output-onlinejpgtools__7_-removebg-preview.png';
import img10 from '../../img/Partner/output-onlinejpgtools__8_-removebg-preview.png';
import img11 from '../../img/Partner/output-onlinejpgtools__9_-removebg-preview.png';

// Import more images as needed...

export const data = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },

  {
    id: 4,
    img: img4,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 6,
    img: img6,
  },

  {
    id: 7,
    img: img7,
  },
  {
    id: 8,
    img: img8,
  },
  {
    id: 9,
    img: img9,
  },

  {
    id: 10,
    img: img10,
  },

  {
    id: 11,
    img: img11,
  },
  
  
  // Rest of your data array remains the same
];
