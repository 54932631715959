import React from 'react'
import background from '../img/contactus.jpg'
import {SiBattledotnet} from 'react-icons/si'
import { RiContactsLine } from 'react-icons/ri';
import { CiMobile3 } from 'react-icons/ci';
import { FiPhoneCall } from 'react-icons/fi';
import { MdEmail} from 'react-icons/md';
import {FaHome} from 'react-icons/fa'
import {motion} from 'framer-motion'
export default function ContactUs() {
    const containerStyle = {
        backgroundImage: `url(${background})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '35vh',
        position: 'relative',
        transition: 'background-image 0.6s ease',
        
        
      };
      const overlayStyle = {
        content: '',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Adjust the opacity (last value) for darkness
      };
  return (
    <motion.div
    initial={{ x: -100, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: 100, opacity: 0, transition: { duration: 0.3 } }}
    >
    <div className=''>
        <div className='bg-black opacity-95  relative' 
        style={containerStyle}>
        <div style={overlayStyle}></div>
        <div className="absolute text-5xl text-white mt-24 justify-end uppercase ml-20 tracking-wide">
                Contact Us
              <div className='flex gap-6'>
              <div className='flex gap-2 mt-8'>
               <SiBattledotnet className='ml-10 text-2xl mt-1'/> <button className='flex text-xl'><a href='/'>Home</a></button>
               </div>
               <div className='flex mt-7'>
                 <button className='text-lg  opacity-80 text-green-400 p-1 rounded-full'><a href='/contactUs'>Contact US</a></button>
               </div>
              </div>
             
        </div>
        

        </div>
       
        <div className="w-auto items-center justify-center contactus mt-7">
        <div className=''>
        <div className=''>
          
          <h1 className='text-4xl font-bold text-center mb-3'>Contact Info</h1>
          <p className='text-center'>Feel always free to contact us and clear any clarifications.</p>
          <p className='text-center mb-5'> We have dedicated staff in order to help you out with your inquiries. Call us Today!</p>
          <div className='text-black-500 animate-fade-in-letters py-4 flex flex-col md:flex-row md:justify-center md:gap-8'>
            <div className="bg-white shadow-lg rounded-lg p-4">
              <div className="mt-2">
                <h3 className="text-xl font-bold text-gray-800"> <CiMobile3 className="inline-block mr-2 text-blue-500 text-5xl" />Mobile &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
                
                <p className="text-gray-600">+94 71 449 2170</p>
              </div>
            </div>

            <div className="bg-white shadow-lg rounded-lg p-4">
              <div className="mt-2">
                <h3 className="text-xl font-bold text-gray-800"> <FiPhoneCall className="inline-block mr-2 text-blue-600 text-5xl" />Hotline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
              
                <p className="text-gray-600">+94 11 25 18 058</p>
              </div>
            </div>

            <div className="bg-white shadow-lg rounded-lg p-4">
              <div className="mt-2">
                <h3 className="text-xl font-bold text-gray-800"><MdEmail className="inline-block mr-2 text-blue-500 text-5xl" />Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
                <p className="text-gray-600">gleelanka@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
 <br/>
      <div className=''>
        <div className=''>
          

          <div className='text-black-500 animate-fade-in-letters py-4 flex flex-col md:flex-row md:justify-center md:gap-8'>
            <div className="bg-white shadow-lg rounded-lg p-4">
              <div className="mt-2">
                <h3 className="text-xl font-bold text-gray-800"> <FaHome className="inline-block mr-2 text-blue-600 text-5xl" />Head Office</h3>
                <p className="text-gray-600">188/1, Piliyandala Road, Werahera,</p>
                <p className="text-gray-600">Boralesgamuwa, Sri Lanka</p>
              </div>
            </div>

            
          </div>

          </div>

      </div>
      
      <div className='flex justify-center p-2'>
      <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10844.264775539074!2d79.90147253718293!3d6.835559473819053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25b557886c443%3A0x6025349db25c07d!2sGreen%20Lanka%20Energy%20engineering%20Co%20(Pvt)%20Ltd!5e0!3m2!1sen!2slk!4v1690953270234!5m2!1sen!2slk"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
            ></iframe>
           </div>


              </div>
            
      
    </div>
    </motion.div>
  )
}